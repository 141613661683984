.Grid {
  --item-left-offset: 40px;

  position: relative;
}

.Grid__slot {
  height: 60px;
  padding: 0.05rem 0.3rem;
  color: #777;
  font-size: 14px;
  display: block;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.Grid__slot:nth-child(odd) {
  background-color: #eee;
}

.Grid__appointment {
  font-size: 14px;
  line-height: 1.25;
  position: absolute;
  left: var(--item-left-offset);
  right: 0.5rem;
  padding: 0.1rem 0.75rem;
  display: block;
  border-left: 3px solid green;
  border-radius: 0.5rem;
  overflow: hidden;
}

.Grid__appointment > * {
  position: relative;
  z-index: 2;
}

.Grid__appointment::after {
  content: '';
  inset: 0;
  position: absolute;
  background-color: rgba(0, 100, 0, 0.25);
  backdrop-filter: blur(5px);
  z-index: 1;
}

.Grid__appointment--warning {
  border-color: tomato;
}

.Grid__appointment--warning::after {
  background-color: rgba(255, 71, 71, 0.25);
}

.Grid__break {
  left: var(--item-left-offset);
  right: 0.5rem;
  position: absolute;
  border-left: 3px solid rgba(var(--bs-warning-rgb), 1);
  color: #777;
  font-style: italic;
  font-size: 14px;
  line-height: 1.25;
  border-radius: 0.5rem;
  backdrop-filter: blur(5px);
}
